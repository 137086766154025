<template>
	<div class="sankey">
		<div class="data">
			Total: {{ allVotes }} |
			Von anderen: {{ gainedVotes }} ({{ gainedPercentage }}%) |
			Zu anderen: {{ lostVotes }} ({{ lostPercentage }}%) |
			Netto: {{ (gainedPercentage -  lostPercentage).toFixed(2) }}%
		</div>
		<div id="sankey"></div>
	</div>
</template>
<script>
import * as d3 from 'd3v4';
import {
	sankeyLinkHorizontal,
	sankey as sankeyGraph
} from '@monfera/d3-sankey';
import Full2020 from '../data/2023_bgr.json';

export default {
	props: ['party'],
	methods: {
		parseData() {

			let foundParties = [];

			let allParties = this.rawData.filter(set => {

				if (!foundParties.includes(set.parteikurzbezeichnung)) {
					foundParties.push(set.parteikurzbezeichnung);
					return true;
				}

				return false;
			});

			let spIndex;
			foundParties.forEach(function (value, index) {
				if (value == this.party) spIndex = index;

				this.data.nodes.push({
					node: index,
					name: value,
					partyColor: this.colors[value],
					partyCode: this.partyCodes[value]
				});
			}.bind(this));

			foundParties.forEach(function (value, index) {
				this.data.nodes.push({
					node: index,
					name: value,
					partyColor: this.colors[value],
					partyCode: this.partyCodes[value]
				});
			}.bind(this));

			let spdata = this.rawData.filter(set => {
				return set.parteikurzbezeichnung == this.party;
			});

			this.allVotes = 0;
			spdata.forEach(function (value, index) {
				this.allVotes += value.stimmen_total_aus_wahlzettel;

				foundParties.forEach(function (party, index) {
					let num = value[this.partyCodes[party]];
					if (party !== this.party && num) {
						this.gainedVotes += num;
						let source = this.data.nodes.filter(node => {
							return node.name === party
						});

						this.data.links.push({
							source: source[0].node,
							target: spIndex,
							value: num
						});
					}
				}.bind(this))

			}.bind(this));

			let noneSp = this.rawData.filter(set => {
				return set.parteikurzbezeichnung != this.party;
			});

			noneSp.forEach(function (value, index) {

				foundParties.forEach(function (party, index) {
					if (party === this.party) {
					let num = value[this.partyCodes[party]];

						if (num) {
							this.lostVotes += num;
							let target = this.data.nodes.filter(node => {
								return node.name === value.parteikurzbezeichnung
							});
							this.data.links.push({
								source: spIndex,
								target: target[0].node + foundParties.length,
								value: num
							});
						}
					}
				}.bind(this))

			}.bind(this));

			this.gainedPercentage = ((this.gainedVotes / this.allVotes) * 100).toFixed(2);
			this.lostPercentage = ((this.lostVotes / this.allVotes) * 100).toFixed(2);
		}
	},
	data() {
		return {
			allVotes: 0,
			lostVotes: 0,
			gainedVotes: 0,
			gainedPercentage: 0,
			lostPercentage: 0,
			rawData: Full2020,
			data: {
				nodes: [],
				links: []
			},
			colors: {
				Ohne: '#00cc33',
				GP: '#00cc33',
				BastA: '#E7007E',
				SP: '#fa0304',
				MITTE: '#fd9900',
				GLP: '#cbd662',
				LDP: '#343398',
				FDP: '#0267f7',
				SVP: '#026201',
				EVP: '#ffff06'
			},
			partyCodes: {
				Ohne: "00_ohne",
				GP: '17_gp',
				BastA: '27_basta',
				SP: '05_sp',
				MITTE: '07_mitte',
				GLP: '10_glp',
				LDP: '03_ldp',
				FDP: '01_fdp',
				SVP: '12_svp',
				EVP: '04_evp',
				Unab: '30_unabh'
			}
		}
	},
	mounted() {
		this.parseData();

		let margin = {top: 0, right: 250, bottom: 200, left: 0};
		let width = window.innerWidth - margin.left - margin.right;
		let height = window.innerHeight - margin.top - margin.bottom;

		var svg = d3.select("#sankey").append("svg")
		.attr("width", width + 100)
		.attr("height", height + 50)
		.append("g")
		.attr("transform",
				"translate(" + margin.left + "," + margin.top + ")");

		var sankey = sankeyGraph()
		.nodeWidth(6)
		.nodePadding(20)
		.size([width, height]);

		sankey
		.nodes(this.data.nodes)
		.links(this.data.links)
		.layout(1);

		var link = svg.append("g")
		.selectAll(".link")
		.data(this.data.links)
		.enter()
		.append("path")
		.attr("class", "link")
		.attr("d", sankey.link())
		.style("stroke-width", function (d) {
			return Math.max(40, d.dy);
		})
		.sort(function (a, b) {
			return b.dy - a.dy;
		});

		var node = svg.append("g")
		.selectAll(".node")
		.data(this.data.nodes)
		.enter().append("g")
		.attr("class", "node")
		.attr("transform", function (d) {
			return "translate(" + d.x + "," + d.y + ")";
		})
		.style("display", function (d) {
			if (d.value == 0) {
				return 'none';
			}
		});

		node
		.append("rect")
		.attr("height", function (d) {
			return d.dy;
		})
		.attr("width", sankey.nodeWidth())
		.style("fill", function (d) {
			return d.partyColor;
		})
		.style("stroke", function (d) {
			return d.partyColor;
		})
		.append("title")
		.text(function (d) {
			return d.name + " " + d.value + " Stimmen";
		});

		node
		.append("text")
		.attr("x", 12)
		.attr("y", function (d) {
			return d.dy / 2;
		})
		.attr("dy", ".35em")
		.attr("text-anchor", "start")
		.attr("transform", null)
		.text(function (d) {
			console.log(this.party);
			if (d.name === this.party) return d.name;

			return d.name + " (" + d.value + ")";
		}.bind(this))
		.filter(function (d) {
			return d.x < width / 2;
		})
		.attr("x", 6 + sankey.nodeWidth())
		.attr("text-anchor", "start")
	}
}
</script>
<style>
.link {
	fill: #fa0304;
	stroke: none;
	stroke-opacity: .2;
	opacity: .2;
}

.sankey {
	position: relative;
}
</style>