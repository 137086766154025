<template>
	<div id="graph">
		<div class="select">
			<v-select :options="partyList" :reduce="party => party.code"
					label="name" v-model="defaultSelected" :value="party" dense
								solo :clearable="false" @input="changeParty"
			/>
		</div>
		<SankeyBGR v-if="renderSankey" :party="party"></SankeyBGR>
	</div>
</template>
<script>
import Bars from '@/components/Bars.vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SankeyBGR from '../components/SankeyBGR.vue';

export default {
	name: "Table",
	components: {
		Bars,
		vSelect,
		SankeyBGR
	},
	methods: {
		changeParty(party) {
			this.party = party;

			this.renderSankey = false;

			this.$nextTick(() => {
				this.renderSankey = true;
			});
		}
	},
	data() {
		return {
			party: 'SP',
			renderSankey: true,
			defaultSelected: {
				name: 'Sozialdemokratische Partei',
				code: "SP"
			},
			partyList: [
				{
					name: 'Sozialdemokratische Partei',
					code: "SP"
				},
				{
					name: 'Grüne',
					code: "GP"
				},
				{
					name: 'BastA',
					code: "BastA"
				},
				{
					name: 'LDP Liberal-Demokratische',
					code: "LDP"
				},
				{
					name: 'Schweizerische Volkspartei',
					code: "SVP"
				},
				{
					name: 'Grünliberale',
					code: "GLP"
				},
				{
					name: 'FDP.Die Liberalen',
					code: "FDP"
				},
				{
					name: 'Mitte',
					code: "MITTE"
				},
				{
					name: 'Evangelische Volkspartei',
					code: "EVP"
				},
			]
		}
	}
}
</script>
<style scoped>

#graph {
	margin-top: 50px;
	width: 95%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.select {
	margin-top: 50px;
	width: 100%;
	margin-bottom: 15px;
}
.v-select {
	background: white !important;
	width: 50%;
}

</style>